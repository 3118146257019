import { NuxtLink } from '../../.nuxt/components';
<template>
  <div class="base-nav-container h-67" :class="isScrollHeader ? 'headerBg' : ''">
    <div class="base-nav-content flex h-67 justify-between" style="margin: 0 auto">
      <div class="flex">
        <div class="logo mt-11">
          <a href="/">
            <img src="~/assets/images/common/logo.svg" style="width: 159px; height: 40px" alt="" />
          </a>
        </div>

        <div class="flex" style="display: none">
          <a :href="localePath(`/`)">
            <div class="base-nav-item pointer mr-40 pt-21 text-16" :class="currentRoutePath.indexOf('index') != -1 ? 'active' : ''">
              {{ $t('CS2指数') }}
            </div>
          </a>
          <a :href="localePath(`/trend`)">
            <div class="base-nav-item pointer mr-40 pt-21 text-16" :class="currentRoutePath.indexOf('trend') != -1 ? 'active' : ''">
              {{ $t('饰品类型走势') }}
            </div>
          </a>
          <a :href="localePath(`/mkt`)">
            <div class="base-nav-item pointer mr-40 pt-21 text-16" :class="currentRoutePath.indexOf('mkt') != -1 ? 'active' : ''">
              {{ $t('全网比价') }}
            </div>
          </a>
          <a :href="localePath('/cs2/tracker')">
            <div class="base-nav-item pointer mr-40 pt-21 text-16" :class="currentRoutePath.indexOf('tracker') != -1 ? 'active' : ''">
              {{ $t('饰品追踪') }}
            </div>
          </a>
          <a :href="localePath('/inventory')">
            <div
              class="base-nav-item pointer mr-40 pt-21 text-16"
              :class="currentRoutePath.indexOf('inventory') != -1 && currentRoutePath.indexOf('inventory-my') == -1 ? 'active' : ''"
            >
              {{ $t('库存估价') }}
            </div>
          </a>
          <el-dropdown class="nav-dropdown" @command="dropdownJump">
            <span class="base-nav-item flex items-center text-16">
              {{ toolsStr }}
              <i class="iconfont icon-xiangxiahuadong ml-5 text-8"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="item in tools" :key="item.key" :command="item.command">
                  {{ item.title }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <el-menu :default-active="activeIndex" class="el-menu-demo w-650" mode="horizontal" :router="true">
          <el-menu-item index="/">{{ $t('CS2指数') }}</el-menu-item>
          <el-sub-menu index="/trend">
            <template #title>
              <a :href="localePath('/trend')">{{ $t('饰品') }}</a>
            </template>
            <el-menu-item index="/trend">{{ $t('饰品走势') }}</el-menu-item>
            <el-menu-item index="/cs2/tracker">{{ $t('饰品追踪') }}</el-menu-item>
            <el-menu-item index="/selection">{{ $t('高级选品') }}</el-menu-item>
          </el-sub-menu>
          <el-menu-item index="/ladders/hanging">{{ $t('挂刀/套现') }}</el-menu-item>
          <el-menu-item index="/ladders">{{ $t('排行榜') }}</el-menu-item>
          <el-menu-item index="/mkt">{{ $t('全网比价') }}</el-menu-item>

          <el-menu-item index="/inventory">{{ $t('库存估价') }}</el-menu-item>

          <el-menu-item index="/skindiy">{{ $t('饰品DIY') }}</el-menu-item>
        </el-menu>
      </div>
      <ClientOnly>
        <div class="flex">
          <ClientOnly>
            <el-autocomplete
              v-model="pageQuery.queryContent"
              class="common-search mr-10 h-46 w-300 self-center"
              :style="userInfo?.id ? 'width: 240px' : 'width: 240px'"
              :placeholder="$t('搜索...')"
              popper-class="my-suggestion-popper"
              :popper-append-to-body="false"
              :fetch-suggestions="querySearch"
              @select="handleSelect"
            >
              <template #suffix>
                <div class="flex items-center">
                  <i class="iconfont icon-sousuo1 text-color-primary text-20"></i>
                </div>
              </template>

              <template #default="{ item }">
                <div class="flex items-center">
                  <img :src="item.url" alt="" style="width: 40px" /> <span class="ml-5 text-14 text-gray-light">{{ item.name }}</span>
                </div>
              </template>
            </el-autocomplete>
          </ClientOnly>

          <el-dropdown class="flex items-center" popper-class="my-dropdown-popper">
            <div class="el-dropdown-link flex w-90 items-center justify-center">
              <span class="mr-5 text-14 text-white">{{ unitDropdown.text }}</span>
              <i class="iconfont icon-xiangxiahuadong text-5 text-white"></i>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="w-90">
                <el-dropdown-item
                  v-for="(item, index) in commonDictionary?.rates"
                  :key="index"
                  :class="item.currency === unitDropdown.text ? 'active' : ''"
                  @click.stop="chooseUnit(item)"
                >
                  <span class="text">{{ item.currency }}</span>
                  <span class="ml-10">{{ item.icon }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown v-if="false" class="invisible flex items-center" popper-class="my-dropdown-popper">
            <div class="el-dropdown-link flex w-140 items-center justify-center">
              <span class="mr-5 text-14 text-white">{{ priceDropdown.text }}</span>
              <i class="iconfont icon-xiangxiahuadong text-5 text-white"></i>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="w-140">
                <el-dropdown-item
                  v-for="(item, index) in priceDropdown.list"
                  :key="index"
                  :class="item.text === priceDropdown.text ? 'active' : ''"
                  @click.stop="choosePrice(item.text)"
                >
                  <span class="text">{{ item.text }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown class="flex items-center" popper-class="my-dropdown-popper">
            <div class="el-dropdown-link flex w-100 items-center justify-center">
              <span class="mr-5 text-14 text-white">{{ languageDropdown.text }}</span>
              <i class="iconfont icon-xiangxiahuadong text-5 text-white"></i>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="w-100">
                <el-dropdown-item
                  v-for="(item, index) in languageDropdown.list"
                  :key="index"
                  :class="item.text === languageDropdown.text ? 'active' : ''"
                  @click.stop="chooseLanguage(item)"
                >
                  <span class="text">{{ item.text }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <div
            v-show="!userInfo?.id"
            class="steam-login flex h-46 w-132 cursor-pointer items-center justify-center self-center justify-self-end rounded-[10px] bg-blue text-white"
            @click="steamLogin"
          >
            <i class="iconfont icon-Steam text-24"></i>
            <span class="login-for-steam ml-10 text-16">{{ $t('Steam登录') }}</span>
          </div>

          <div v-if="userInfo?.id" class="flex items-center">
            <ClientOnly>
              <mytooltip :class="['nav-user-info-tooltip', 'anymore']">
                <template #default>
                  <div class="action-bg flex cursor-pointer items-center px-10">
                    <el-avatar :size="30" :src="userInfo.avatar">
                      <img src="https://avatars.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg" alt="avatar" />
                    </el-avatar>
                    <!-- <div class="ml-5 text-14 text-white opacity-60">{{ userInfo.nickname }}</div> -->
                  </div>
                </template>
                <template #content>
                  <div class="user-info-content px-15 pt-24 text-14">
                    <div class="mb-20 flex">
                      <div class="">
                        <el-avatar :src="userInfo.avatar" :size="50" class="mr-10 h-60 w-60 overflow-hidden rounded-full">
                          <img src="https://avatars.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg" alt="avatar" />
                        </el-avatar>
                      </div>
                      <div>
                        <div class="mb-10 text-14 font-semibold">{{ userInfo.nickname }}</div>
                        <div class="logout cursor-pointer text-14 text-gray-light">
                          <span class="user-id text-color-lightgray mr-10">ID: {{ userInfo.id }}</span>
                          <i class="iconfont icon-fuzhi1" @click="copyUserId"></i>
                        </div>
                      </div>
                    </div>
                    <div class="tracker-info mb-15 flex h-72 w-310 items-center justify-between px-10 py-15">
                      <div>
                        <div class="mb-5 text-white">
                          <span>{{ $t('饰品追踪剩余次数') }}：</span>
                          <span class="text-14">{{ userInfo.traceTimes }}</span>
                        </div>
                        <div class="text-color-white opacity-80">{{ $t('每日获得5次，凌晨0点重置') }}</div>
                      </div>
                      <div>
                        <div class="text-color-primary relative flex h-30 cursor-pointer items-center rounded-[15px] bg-white px-12 leading-[30px]" @click="copyShare">
                          <img src="../../assets/images/layouts/share-tips.webp" style="position: absolute; top: -25px; left: -45px; width: 95px; max-width: 95px" alt="" />
                          <i class="iconfont icon-lianjie mr-5"></i>
                          <span>{{ $t('分享') }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <a :href="localePath('/inventory/my')">
                        <div class="user-tooltip-item text-color-lightgray h-61 w-73 cursor-pointer rounded pt-11">
                          <div class="text-center"><i class="iconfont icon-wodekucun"></i></div>
                          <div class="text-center">{{ $t('我的库存') }}</div>
                        </div>
                      </a>

                      <!-- <div class="user-tooltip-item text-color-lightgray h-61 w-73 cursor-pointer rounded pt-11">
                        <div class="text-center"><i class="iconfont icon-wodeguanzhu"></i></div>
                        <div class="text-center">{{ $t('我的关注') }}</div>
                      </div> -->
                      <a :href="localePath('/faq')">
                        <div class="user-tooltip-item text-color-lightgray h-61 w-73 cursor-pointer rounded pt-11">
                          <div class="text-center"><i class="iconfont icon-changjianwenti2"></i></div>
                          <div class="text-center">{{ $t('常见问题') }}</div>
                        </div>
                      </a>
                      <a :href="localePath('/terms/DevPlan')">
                        <div class="user-tooltip-item text-color-lightgray h-61 w-73 cursor-pointer rounded pt-11">
                          <div class="text-center"><i class="iconfont icon-kaifajihua1"></i></div>
                          <div class="text-center">{{ $t('开发计划') }}</div>
                        </div>
                      </a>
                    </div>

                    <!-- <div class="">
                      <div class="mb-5 flex justify-between text-14">
                        <div>
                          <span>{{ $t('饰品追踪剩余次数') }}：</span>
                          <span class="text-color-primary text-14">{{ userInfo.traceTimes }}</span>
                        </div>
                        <div>
                          <img src="~/assets/images/layouts/share-tips.webp" alt="" />
                        </div>
                      </div>
                      <div class="text-color-lightgray flex justify-between text-14">
                        <div>{{ $t('每日获得5次，凌晨0点重置') }}</div>
                        <div class="text-color-primary flex items-center" @click="copyShare">
                          <i class="iconfont icon-lianjie mr-5"></i>
                          <span class="cursor-pointer underline">{{ $t('分享链接') }}</span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <el-divider style="margin: 15px 0"></el-divider>
                  <div class="mb-10 flex justify-center text-14">
                    <div class="text-color-lightgray hover:bg-primary logout flex cursor-pointer items-center" @click="logout">
                      <i class="iconfont icon-tuichu mr-5"></i>{{ $t('退出登录') }}
                    </div>
                  </div>
                </template>
              </mytooltip>
            </ClientOnly>
          </div>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup>
import mytooltip from '../common/mytooltip.vue'
import { getCommonDictionary } from '~/api/common'
import { getAutoComletion } from '~/api/steam'
import { userLogout } from '~/api/steam'
import copyShareUrl from '~/utils/copyshareUrl'
import { add } from '~/utils/index'

const route = useRoute()
const router = useRouter()
const { locale, setLocale, t } = useI18n()
const $t = t
const routeName = route.name
console.log('route', route)
// 创建一个响应式引用来存储当前的路由路径
const currentRoutePath = ref(route.name)
const store = useNuxtStore()
let userInfo = ref({})
const toolsStr = ref($t('工具'))
const activeIndex = route.path.replace('/en', '')
const tools = [
  {
    key: '1',
    title: $t('选品'),
    command: 'selection'
  },
  {
    key: '2',
    title: $t('饰品DIY'),
    command: 'skindiy'
  }
]
// 使用 watch 监听路由路径的变化
watch(
  () => route.name,
  (newPath, oldPath) => {
    console.log('路由变更:', oldPath, '=>', newPath)
    // 在这里执行你的逻辑
    currentRoutePath.value = newPath
  }
)
watch(
  () => store.userInfo,
  (newval, oldval) => {
    console.log('watch.userInfo', newval)
    userInfo.value = newval
  }
)

const { data: commonDictionary } = await useLazyAsyncData(getCommonDictionary, {
  server: false
})
watch(commonDictionary, () => {
  if (!window.localStorage.getItem('commonDictionary') && commonDictionary) {
  }
})

const isScrollHeader = ref(false)
const unitDropdown = reactive({
  text: useCookie('SDT_MonetaryUnit')._value?.currency || 'CNY',
  list: [
    {
      text: 'USD',
      unit: '$',
      left: '10'
    },
    {
      text: 'EUR',
      unit: '€',
      left: '12'
    },
    {
      text: 'CNY',
      unit: '￥',
      left: '7'
    },
    {
      text: 'AUD',
      unit: 'A$',
      left: '7'
    }
  ]
})
const priceDropdown = reactive({
  text: 'C5GAME价格',
  list: [
    {
      text: 'C5GAME价格'
    },
    {
      text: 'BUFF价格'
    },
    {
      text: '悠悠有品价格'
    },
    {
      text: 'IGXE价格'
    }
  ]
})
const languageDropdown = reactive({
  text: (useCookie('i18n_redirected').value && useCookie('i18n_redirected').value == 'en') || useRoute().path.includes('/en') ? 'English' : '中文',
  list: [
    {
      text: 'English',
      lang: 'en'
    },
    {
      text: '中文',
      lang: 'zh'
    }
  ]
})

onMounted(() => {
  window.addEventListener('scroll', scrollHeader)
  const token = useCookie('SDT_AccessToken')
  console.log('token', token.value)
  console.log('route', route)
  if (route.path.includes('selection')) {
    toolsStr.value = $t('选品')
  } else if (route.path.includes('skindiy')) {
    toolsStr.value = $t('饰品DIY')
  }
  if (!token.value) {
    console.log('store.userInfo.value', store.userInfo)
    if (store.userInfo?.id) {
      console.log('store.userInfo.value1', store.userInfo)
      myMessage({
        type: 'error',
        message: '登录状态已失效，请重新登录',
        duration: 3000
      })
      store.setUserInfo({})
    }
  } else {
    userInfo.value = store.userInfo
  }
})

function changeTabs(name) {
  console.log('变更路由', name)
  routeName.value = name
}
// const routeName = route.name
function steamLogin() {
  window.localStorage.removeItem('steamloginStatus')
  const location = window.location
  const steamLoginUrl = `https://steamcommunity.com/openid/login?openid.mode=checkid_setup&openid.ns=http://specs.openid.net/auth/2.0&openid.realm=${location.origin}&openid.sreg.required=nickname,email,fullname&openid.assoc_handle=None&openid.return_to=${location.origin + '/login/steam'}&openid.ns.sreg=http://openid.net/extensions/sreg/1.1&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select`
  window.open(steamLoginUrl, '_blank', 'popup,width=500,height=600')
  window.addEventListener('storage', function (e) {
    console.log('storage', e)
    if (e.key == 'steamloginStatus') {
      console.log('storageValue', e.newValue)
      if (e.newValue) {
        window.localStorage.removeItem('steamloginStatus')
        myMessage({
          type: 'success',
          message: 'Steam登录成功',
          duration: 2000,
          onClose: () => {
            location.reload()
          }
        })
      } else {
        window.localStorage.removeItem('steamloginStatus')
        myMessage({
          type: 'error',
          message: 'Steam登录失败，请重试',
          duration: 3000
        })
      }
    }
  })
}
function logout() {
  userLogout()
  store.setUserInfo({})
  const token = useCookie('SDT_AccessToken')
  const mySteamToken = useCookie('SDT_MySteamToken')
  token.value = null
  mySteamToken.value = null
  myMessage({
    type: 'success',
    message: '退出登录成功',
    duration: 2000,
    onClose: () => {
      location.reload()
    }
  })
}
async function copyShare() {
  const remainTime = await copyShareUrl()
  myMessage({
    type: 'success',
    message: '分享链接已复制',
    duration: 2000
  })
  userInfo.value = {
    ...userInfo.value,
    traceTimes: remainTime
  }
  store.setUserInfo(userInfo.value)
}

function copyUserId() {
  console.log('userInfo', userInfo)
  copy(userInfo.value.id)
  myMessage({
    type: 'success',
    message: '复制成功',
    duration: 2000
  })
}
function scrollHeader() {
  // 获取页面滚动的距离
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

  // 滚动页面头部
  if (scrollTop > 0) {
    isScrollHeader.value = true
  } else {
    isScrollHeader.value = false
  }
}

// 选择金额单位
function chooseUnit(item) {
  const monetaryUnit = useCookie('SDT_MonetaryUnit')

  monetaryUnit.value = JSON.stringify(item)
  unitDropdown.text = item.currency
  clearNuxtData()
  window.location.reload(true)
}

// 选择价格
function choosePrice(val) {
  priceDropdown.text = val
}

// 选择语言
function chooseLanguage(val) {
  const i18nRedirected = useCookie('i18n_redirected')

  i18nRedirected.value = val.lang

  languageDropdown.text = val.text
  setLocale(val.lang)
  setTimeout(() => {
    getCommonDictionary(1).then(res => {
      location.reload()
    })
  }, 10)
}

function dropdownJump(command) {
  const localePath = useLocalePath()
  window.location.href = localePath(`/${command}`)
  // router.push(localePath(`/${command}`))
}

const pageQuery = ref({
  queryContent: ''
})

function handleSelect(item) {
  const localePath = useLocalePath()
  location.href = localePath(`/cs2/${item.marketHashName}`)
}
function querySearch(queryString, cb) {
  getAutoComletion({
    content: encodeURIComponent(queryString)
  }).then(res => {
    if (res.data && res.data[0]) {
      cb(res.data)
    }
  })
}
function menuSelect(index) {
  const localePath = useLocalePath()
  location.href = localePath(index)
  return false
}
</script>

<style lang="scss" scoped>
@import url(~/assets/styles/_color.scss);
.base-nav-container {
  width: 100%;
  // background-color: rgba($color: #fff, $alpha: 0.8);
  z-index: 3;
  position: fixed;
  transition: all 0.3s linear;
  border-bottom: 1px solid rgba($white-color, 0.15);
  background: url('~/assets/images/layouts/nav-bg.png') top;
  background-size: cover;
  &.headerBg {
    background: url('~/assets/images/header-bgs/index-nav-bg.jpg') no-repeat #2a2d3c;
    border-color: #2a2d3c;
    box-shadow: #12182c 0px 0px 5px;
    // -webkit-backdrop-filter: blur(5px) brightness(0.2);
    // backdrop-filter: blur(5px) brightness(0.2);
  }

  .base-nav-content {
    max-width: 1400px;
    height: 65px;
    .el-menu-demo {
      :deep(.el-menu-item) {
        background-color: transparent !important;
      }
    }

    .nav-dropdown {
      outline: none;
      * {
        outline: none;
      }
      &:hover {
        outline: none;
      }
    }
    .base-nav-item {
      color: $light-blue-color;
      // opacity: 0.9;
      transition: all 0.1s linear;
      height: 100%;
      &.active {
        color: $white-color;
        border-bottom: 2px solid $white-color;
        opacity: 1;
      }
      &:hover {
        color: $white-color;
        border-bottom: 2px solid $white-color;
        opacity: 1;
      }
    }
    &:deep(.common-search) {
      .el-input__wrapper {
        height: 46px;
        background-color: rgba(#fff, 0.1);
        border: none;
        box-shadow: none;
        transition: all 0.3s;
        i {
          color: rgba(#fff, 0.8);
        }
        &.is-focus {
          background-color: #fff;

          i {
            color: $primary-color;
          }
        }
      }
    }
    &:deep(.el-menu) {
      height: 67px;
      background-color: transparent;
      border-bottom-color: transparent;
      .el-menu-item {
        color: rgba(#fff, 0.8);
        font-size: 16px;
        &:hover {
          color: #fff;
          background-color: transparent;
        }
        &.is-active {
          color: #fff;
          background-color: transparent;
        }
      }
      .el-sub-menu__title {
        color: #fff;
        font-size: 16px;
        &:hover {
          // color: #fff;
          background-color: transparent;
        }
      }
    }
  }

  .steam-login {
    border: 1px solid $primary-color;
  }

  :deep(.el-dropdown) {
    .el-dropdown-link {
      padding: 0 15px 0 20px;
      margin-right: 10px;
      height: 46px;
      background: rgba(#fff, 0.1);
      color: rgba(#fff, 0.8);
      border-radius: 10px;
      outline: 0 none;
      // opacity: 0.6;
      transition: all 0.3s;
      &:hover {
        opacity: 1;
        background: #fff;
        color: #000;
        span {
          color: #000;
        }
        i {
          color: #000;
        }
        .iconfont {
          transform: rotate(180deg);
        }
      }
    }
  }

  .action-bg {
    height: 46px;
    background: rgba(#fff, 0.1);
    border-radius: 10px;
  }

  .logout {
    &:hover {
      color: $primary-color;
    }
  }
}
</style>
<style lang="scss">
@import url('~/assets/styles/_color.scss');

.nav-user-info-tooltip {
  width: 340px;
  // box-sizing: border-box;
  // padding: 24px 15px 10px 15px !important;
  padding: 0 !important;
  background: url(~/assets/images/layouts/user-tooltip-bg.webp) top no-repeat !important;
  background-color: #fff !important;
  background-size: 100% auto;
  .user-info-content {
    .tracker-info {
      background: url(~/assets/images/layouts/tracker-info-bg.webp) no-repeat !important;
    }
    .icon-fuzhi1 {
      &:hover {
        color: $primary-color;
      }
    }
    .user-tooltip-item {
      &:hover {
        background-color: rgba($alpha: 0.1, $color: #0252d9);
        color: #000;
        .iconfont {
          color: $primary-color;
        }
      }
    }
  }
}
.user-info-content {
}

.my-dropdown-popper {
  background: $white-color !important;
  border: 1px solid $border-gray-color !important;
  box-shadow: 0 0 0;
  margin-top: -12px;

  .el-dropdown-menu {
    padding: 0;
    background-color: $white-color;
    border-radius: 4px;

    &__item {
      font-size: 14px;
      color: $light-gray-color !important;
      line-height: 20px;
      padding: 8px 20px;

      &.active,
      &:hover {
        background: $border-gray-color;

        .text {
          color: $text-black-color;
        }
      }
    }
  }

  .el-popper__arrow {
    display: none;
  }
}
// :deep(.my-suggestion-popper) {
//   background-color: red;
//   li {
//     padding-top: 4px;
//     padding-bottom: 4px;
//   }
// }
.my-suggestion-popper {
  li {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  // background-color: red;
  // :deep(li) {
  //   padding-top: 4px !important;
  //   padding-bottom: 4px;
  // }
}
</style>
